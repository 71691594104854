/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Container, CircularProgress, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchForm from './components/SearchForm';
import ResultTable from './components/ResultTable';
import data from './data.json';
import data2 from './data2.json';
import { Data, Survey, Answerer } from './types';

// data.jsonの型を明示的に定義
const jsonData: Data = data as Data;

const App: React.FC = () => {
  const [filteredData, setFilteredData] = useState<Survey[]>([]);
  const [isSearched, setIsSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mergedData, setMergedData] = useState<Answerer[]>([]);
  const [showScroll, setShowScroll] = useState(false);

  /*
  useEffect(() => {
    const genderTypeSet = new Set<string>();
    data2.values.forEach(answerer => {
      console.log(answerer);
      if (answerer.answerer_school_type) {
        genderTypeSet.add(answerer.answerer_school_type);
      }
    });
    console.log(genderTypeSet);
  }, [])
  */

  useEffect(() => {
    // データのマージをページ読み込み時に行う
    const merged = jsonData.surveys[0].answerers.map(answerer => {
      const additionalInfo = data2.values.find(additional => additional.answerer_code === answerer.answerer_code);
      return additionalInfo ? { ...answerer, ...additionalInfo } : answerer;
    });
    setMergedData(merged);
  }, []);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollTop);
    return () => {
      window.removeEventListener('scroll', checkScrollTop);
    };
  }, [checkScrollTop, showScroll]);

  const handleSearch = (schoolName: string, month: string, prefecture: string, genderTypes: string[]) => {
    setIsLoading(true);
    console.log("検索条件:", { schoolName, month, prefecture, genderTypes });
    let result = mergedData;
    console.log(mergedData)

    if (schoolName) {
      result = result.filter(answerer => {
        const nameMatch = answerer.answerer_name.includes(schoolName);
        const hiraganaMatch = (answerer.answerer_business_name_hiragana ?? '').includes(schoolName);
        const commonNameMatch = (answerer.answerer_common_name ?? []).some(commonName => commonName.includes(schoolName));
        return nameMatch || hiraganaMatch || commonNameMatch;
      });
      console.log("学校名でフィルタリング後:", result);
    }

    if (prefecture) {
      result = result.filter(answerer =>
        answerer.answerer_prefecture === prefecture
      );
      console.log("都道府県でフィルタリング後:", result);
    }

    if (genderTypes.length > 0) {
      result = result.filter(answerer => {
        const genderType = answerer.answerer_gender_type === "共" ? "共学" : answerer.answerer_gender_type;
        return genderTypes.includes(genderType as string);
      });
      console.log("学校種別でフィルタリング後:", result);
    }


    if (month) {
      result = result.map(answerer => {
        const filteredAnswers = answerer.answers.map(answer => {
          const filteredColumns = answer.columns.filter(columnRow =>
            columnRow.some(column => {
              const match = column.key === "KJC02-1-1-4" && column.value.includes(month);
              console.log("デバッグ: column", column, "match:", match);
              return match;
            })
          );
          return { ...answer, columns: filteredColumns };
        }).filter(answer => answer.columns.length > 0);
        return { ...answerer, answers: filteredAnswers };
      }).filter(answerer => answerer.answers.length > 0);
      console.log("開催月でフィルタリング後:", result);
    }

    setFilteredData(result.map(answerer => ({
      survey_name: "",
      survey_no: "",
      survey_year: "",
      answerers: [answerer]
    })) as Survey[]);
    setIsSearched(true); // 検索が実行されたことを設定
    console.log("最終フィルタリング結果:", result);
    setIsLoading(false);
  };

  const handleReset = () => {
    setIsSearched(false); // 検索結果を非表示にする
  };

  return (
    <Container>
      <h1>学校行事検索</h1>
      <SearchForm
        onSearch={handleSearch}
        onReset={handleReset}
        />
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        isSearched && <ResultTable data={filteredData} />
      )}
      <Fab
        color="primary"
        size="small"
        onClick={scrollTop}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          display: showScroll ? 'flex' : 'none',
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Container>
  );
};

export default App;