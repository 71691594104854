import React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Survey, Column } from '../types';

interface ResultTableProps {
  data: Survey[];
}

const ResultTable: React.FC<ResultTableProps> = ({ data }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>学校情報</TableCell>
          <TableCell>行事名</TableCell>
          <TableCell>開催日</TableCell>
          <TableCell>
            <Box sx={{width: '4em'}}>
              <Box>授業</Box>
              <Box>見学</Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box sx={{width: '4em'}}>
              <Box>個別</Box>
              <Box>相談</Box>
            </Box>
          </TableCell>
          <TableCell>予約URL</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(survey => (
          survey.answerers.map(answerer => (
            answerer.answers.map((answer, answerIndex) => (
              answer.columns.map((columnRow, columnIndex) => (
                <TableRow
                  key={`${answerer.answerer_code}-${answerIndex}-${columnIndex}`}
                  sx={{
                    '&:nth-of-type(odd)': {
                      backgroundColor: 'action.hover',
                    },
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        fontSize: '0.8em',
                        color: '#696969',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Box sx={{ marginRight: '4px' }}>
                        {answerer.answerer_prefecture}
                      </Box>
                      <Box sx={{ marginRight: '4px' }}>
                        {answerer.answerer_ownership_type}
                      </Box>
                      <Box>
                        {answerer.answerer_gender_type}
                      </Box>
                    </Box>
                    <Box>
                      {answerer.answerer_name}
                    </Box>
                  </TableCell>
                  <TableCell>{getColumnValue(columnRow, "KJC02-1-1-2")}</TableCell>
                  <TableCell>
                    <Box sx={{ width: '7em' }}>
                      {getColumnValue(columnRow, "KJC02-1-1-4")}
                    </Box>
                  </TableCell>
                  <TableCell>{getColumnValue(columnRow, "KJC02-1-1-18")}</TableCell>
                  <TableCell>{getColumnValue(columnRow, "KJC02-1-1-19")}</TableCell>
                  <TableCell>
                    {getColumnValue(columnRow, "KJC02-1-1-20") ? (
                      <Box sx={{ width: '280px', wordBreak: 'break-all' }}>
                        <a
                          href={getColumnValue(columnRow, "KJC02-1-1-20")}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {getColumnValue(columnRow, "KJC02-1-1-20")}
                        </a>
                      </Box>
                    ) : ''}
                  </TableCell>
                </TableRow>
              ))
            ))
          ))
        ))}
      </TableBody>
    </Table>
  );
};

const getColumnValue = (columns: Column[], key: string): string => {
  const column = columns.find(col => col.key === key);
  return column ? column.value.join(', ') : '';
};

export default ResultTable;