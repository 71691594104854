import React, { useState, forwardRef } from 'react';
import { TextField, Button, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';

interface SearchFormProps {
  onSearch: (schoolName: string, month: string, prefecture: string, genderTypes: string[]) => void;
  onReset: () => void; // リセットボタンのハンドラを追加
}

const CustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
  <TextField
    value={value}
    onClick={onClick}
    ref={ref}
    autoComplete="off"
    fullWidth
  />
));

const SearchForm: React.FC<SearchFormProps> = ({
  onSearch,
  onReset,
}) => {
  const [schoolName, setSchoolName] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [prefecture, setPrefecture] = useState('');
  const [genderTypes, setGenderTypes] = useState<string[]>([]);

  const handleGenderTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setGenderTypes(prev =>
      event.target.checked ? [...prev, value] : prev.filter(type => type !== value)
    );
  };

  const handleSearch = () => {
    const formattedDate = selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '';
    onSearch(schoolName, formattedDate, prefecture, genderTypes);
  };

  const handleReset = () => {
    setSchoolName('');
    setSelectedDate(null);
    setPrefecture('');
    setGenderTypes([]);
    onReset();
  };

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography sx={{ width: '100px' }}>学校名</Typography>
        <TextField fullWidth value={schoolName} onChange={(e) => setSchoolName(e.target.value)} />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography sx={{ width: '100px' }}>学校種別</Typography>
        <FormControlLabel
          control={<Checkbox value="共学" checked={genderTypes.includes('共学')} onChange={handleGenderTypeChange} />}
          label="共学"
        />
        <FormControlLabel
          control={<Checkbox value="男" checked={genderTypes.includes('男')} onChange={handleGenderTypeChange} />}
          label="男子校"
        />
        <FormControlLabel
          control={<Checkbox value="女" checked={genderTypes.includes('女')} onChange={handleGenderTypeChange} />}
          label="女子校"
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography sx={{ width: '100px' }}>開催月</Typography>
        <DatePicker
          selected={selectedDate}
          onChange={(date: Date | null) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
          locale={ja}
          customInput={<CustomInput />}
          wrapperClassName="datePickerWrapper"
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography sx={{ width: '100px' }}>都道府県</Typography>
        <TextField fullWidth value={prefecture} onChange={(e) => setPrefecture(e.target.value)} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        <Button variant="contained" color="primary" onClick={handleSearch} sx={{ whiteSpace: 'nowrap', width: '240px' }}>
          検索
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
        <Button variant="outlined" color="secondary" onClick={handleReset} sx={{ whiteSpace: 'nowrap' }}>
          条件リセット
        </Button>
      </Box>
    </Box>
  );
};

export default SearchForm;